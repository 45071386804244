<template>
  <div class="pinTuIndex-wrap">
    <button @click="$lv.go('/game/index')">退出游戏</button>
    <button @click="replace">重新开始</button>
    <p>所用时间：</p>
    <p>{{h}}小时</p>
    <p>{{m}}分钟</p>
    <p>{{s}}秒</p>
    <ul>
      <li v-for="(item, index) in imgs" :key="index" @click="moveimg(index)" :class="{active:bgnum == index}"><img :src="item.img" alt=""></li>
    </ul>
  </div>
</template>
<script>
import util from '@/util'
export default {
  name: 'pinTuIndex',
  data () {
    return {
      imgs: [{
        id: 1,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_01.jpg'
      }, {
        id: 2,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_02.jpg'
      }, {
        id: 3,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_03.jpg'
      }, {
        id: 4,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_04.jpg'
      }, {
        id: 5,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_06.jpg'
      }, {
        id: 6,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_07.jpg'
      }, {
        id: 7,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_08.jpg'
      }, {
        id: 8,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_09.jpg'
      }, {
        id: '空白',
        img: ''
      }],
      bgnum: null,
      firstimgs: [{
        id: 1,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_01.jpg'
      }, {
        id: 2,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_02.jpg'
      }, {
        id: 3,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_03.jpg'
      }, {
        id: 4,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_04.jpg'
      }, {
        id: 5,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_06.jpg'
      }, {
        id: 6,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_07.jpg'
      }, {
        id: 7,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_08.jpg'
      }, {
        id: 8,
        img: 'http://elemamf.test.upcdn.net/minebook/games/pinTu_09.jpg'
      }, {
        id: '空白',
        img: ''
      }
      ], // 记录最初的imgs
      atimgs: null, // 选中状态索引
      atwhite: 8, // 空白格位置
      atimgsval: null, // 选中状态的内容
      atimgsvalid: null, // 选中状态的id
      h: 0,
      m: 0,
      s: 0,
      j: 0, // 判断是否完成拼图
      timer: null, // 计时器
      arr: [] // 生成九位从1到9的随机数
    }
  },
  methods: {
    moveimg (index) {
      if (index - this.atwhite === 1 || index - this.atwhite === -1 || index - this.atwhite === 0 || index - this.atwhite === -3 || index - this.atwhite === 3) {
        if (this.imgs[index].id !== '空白') {
          this.bgnum = index
          this.atimgs = index
          this.atimgsval = this.imgs[index].img
          this.atimgsvalid = this.imgs[index].id
          // console.log(this.atimgsval)
        } else {
          if (this.atimgsvalid === null) {
            this.atimgsvalid = '空白'
          }
          if (this.atimgsvalid !== '空白') {
            this.imgs[this.atimgs].id = '空白'
            this.imgs[this.atimgs].img = ''
            this.bgnum = null
            this.imgs[this.atwhite].img = this.atimgsval
            this.imgs[this.atwhite].id = this.atimgsvalid
            this.atwhite = this.atimgs
            this.atimgsval = ''
            this.atimgsvalid = null
            this.atimgs = index
            for (var k = 0; k < this.firstimgs.length; k++) {
              if (this.firstimgs[k].id === this.imgs[k].id) {
                this.j++
                if (this.j === 9) {
                  alert('挑战成功')
                  clearInterval(this.timer)
                }
              }
              // console.log(this.j)
            }
            this.j = 0
            // console.log(this.firstimgs)
            // console.log(this.imgs)
          }
        }
      }
    },
    // 计时器
    gettime () {
      this.s++
      if (this.s >= 60) {
        this.s = 0
        this.m++
      }
      if (this.m >= 60) {
        this.m = 0
        this.h++
      }
    },
    // 重新开始
    replace () {
      this.h = 0
      this.m = 0
      this.s = 0
      clearInterval(this.timer)
      this.timer = setInterval(this.gettime, 1000)
      this.setmath()
    },
    // 打乱顺序
    setmath () {
      this.arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8']
      this.arr.sort(util.randomsort)
      for (var k = 0; k < 9; k++) {
        this.imgs[k] = JSON.parse(JSON.stringify(this.firstimgs[this.arr[k]]))
        if (this.imgs[k].id === '空白') {
          this.atwhite = k
        }
      }
    }
  },
  mounted () {
    // console.log(this.imgs)
    this.setmath()
    this.timer = setInterval(this.gettime, 1000)
  }
}
</script>
<style lang="stylus">
.pinTuIndex-wrap
  height 8rem
  background-color green
  overflow none
  position relative
  button
    height 0.8rem
    color #fff
    background-color red
    font-size 0.4rem
    width 1.8rem
  p
    line-height 1rem
    color #fff
    font-size 0.4rem
  ul
    width 6rem
    height 6rem
    background-color yellow
    position absolute
    top 55%
    margin-top -3rem
    left 50%
    margin-left -3rem
    li
      height 2rem
      width 2rem
      float left
      background-color white
      img
        height 100%
        width 100%
.active
  background-color pink !important
</style>
